import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionModalItem } from 'Components';
import { ReactComponent as SaveIcon } from 'Assets/save.svg';
import { ReactComponent as TrashIcon } from 'Assets/trash-black.svg';
import { ReactComponent as ChatIcon } from 'Assets/chat-icon.svg';
import styles from './InfoWarningClose.module.scss';
import { Modal } from 'rsuite';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

const MODAL_TYPE = 'modal';
const COMPACT_TYPE = 'compact';
const DEFAULT_MODAL_TYPE = MODAL_TYPE;

interface ISaveClaimAsDraftModal {
  type?: 'modal' | 'compact';
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onDeny: () => void;
  withReopenChat?: boolean;
}

const STRATEGY = {
  [MODAL_TYPE]: SaveAsDraftModal,
  [COMPACT_TYPE]: SaveAsDraftCompact,
};

function SaveAsDraftModal(props: Omit<ISaveClaimAsDraftModal, 'type'>) {
  const { t } = useTranslation();
  return (
    <CustomModal open={props.open}>
      <CustomModalHeader 
        title={   
          <>
            {t('createClaim.closeHeader')}<br />

          </>
        }   
        onClose={props.onClose}
      />
      <Modal.Body>
        <div className={styles.saveModalContainer}>
        <div className={styles.bodyText}>{t('createClaim.closeContent')}</div>
        <div className={styles.buttonsGroup}>
        <ActionModalItem
              onClick={props.onClose}
              text={t('common.cancel')}
              className={styles.actionItem}
              color='secondary'
          />
          <ActionModalItem
              onClick={props.onDeny}
              text={t('common.confirm')}
              className={styles.actionItem}
              color='primary'
          />
        </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

function SaveAsDraftCompact(props: Omit<ISaveClaimAsDraftModal, 'type'>) {
  const { t } = useTranslation();
  return (
    <CustomModal open={props.open}>
      <CustomModalHeader onClose={props.onClose}/>
      <Modal.Body>
        <div className={styles.saveModalContainer}>
          <ActionModalItem
            onClick={props.onConfirm}
            icon={<SaveIcon />}
            text={t('saveAsDraft.saveActionTitle')}
            description={t('saveAsDraft.saveActionDescription')}
            className={styles.actionItem}
          />
          {props.withReopenChat ? (
            <ActionModalItem
              onClick={props.onDeny}
              icon={<ChatIcon />}
              text={t('saveAsDraft.reopenChatTitle')}
              description={t('saveAsDraft.reopenChatDescription')}
              className={styles.actionItem}
            />
          ) : (
            <ActionModalItem
              onClick={props.onDeny}
              icon={<TrashIcon />}
              text={t('saveAsDraft.discardActionTitle')}
              description={t('saveAsDraft.discardActionDescription')}
              className={styles.actionItem}
            />
          )}
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

export default function InfoWarningClose({
  type = DEFAULT_MODAL_TYPE,
  ...props
}: ISaveClaimAsDraftModal) {
  const Component = STRATEGY[type] as React.FC<Omit<ISaveClaimAsDraftModal, 'type'>>;

  return <Component {...props} />;
}
