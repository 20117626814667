import parse from 'html-react-parser';
import React from 'react';

import useTranslate from "Hooks/useTranslate";
import style from './terms.module.scss';
import { Layout } from "Components";

type MainPropsT = {
    type?: 'terms'|'privacy'
}

const Terms: React.FC<MainPropsT> = ({ type }) => {

    const t = useTranslate();

    return (
        <Layout>
            <div className={style.root}>
                <div className={style.header}>{t(`footer.${type === 'privacy' ? 'privacyPolicy' : 'termsAndConditions'}`)}</div>
                {parse(t(`regs.${type === 'terms' ? 'terms' : 'privacy'}`))}
            </div>
        </Layout>
    );
}
export default Terms;