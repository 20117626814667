import axios from "axios";

import { ApiSendMethods } from "PageComponents/Feed/DataStructure/services";
import runApi, { ResultApiTypeT, url } from "api/base/runApi";
import { IClaimDraft } from "Store/Types/claim";
import { getUrlOfImageAsync } from "./api";

export const isDraftEmpty = (drafts: IClaimDraft[]) => {
    const isEmpty = (draft: IClaimDraft): boolean => {
        if (draft?.referenceNumber) return false;
        if (draft?.case) return false;
        if (draft?.airlineId) return false;
        if (draft?.landingStation) return false;
        if (draft?.dateNextTravel) return false;
        if (draft?.luggage){
            if (!(Object.entries(draft.luggage)?.length === 0 || (Object.entries(draft.luggage)?.length === 1 && draft.luggage?.currencyIsoCode))){
                return false;
            }
        }
        if (draft?.location){
            if (draft.location?.address || draft.location?.city || draft.location?.zipCode || draft.location?.country || draft.location?.formattedAddress){
                return false;
            }
        }
        if (draft?.airlineDateLanding) return false;
        if (draft?.airlineName) return false;
        return true;
    }

    let notEmpty = false;
    for(const draft of drafts){
        if (!isEmpty(draft)){
            notEmpty = true;
        }
    }

    return !notEmpty;
};

export const getClaimDrafts = (callBack?: (result: boolean, data?: any) => void) => {
    runApi(`claim-draft`, {}, (r: ResultApiTypeT) => {
        if (r.result && r.data){
            return callBack?.(true, r.data);
        }
        callBack?.(false);
    }, ApiSendMethods.get);
}

export const setNewDraft = (callBack?: (result: boolean, data?: IClaimDraft) => void) => {
    runApi(`claim-draft`, {}, (r: ResultApiTypeT) => {
        if (r.result && r.data){
            return callBack?.(true, r.data);
        }
        callBack?.(false);
    }, ApiSendMethods.post);
};

export const deleteDraft = async (draftId: string): Promise<any> => {
    const headers: any = {
        'Content-Type': 'application/json',
    };

    const token = sessionStorage.getItem('token');

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    try {
        const result = await axios({
            method: 'DELETE',
            url: url + `claim-draft/${draftId}`,
            headers
        });

        return result;
    } catch (error) {
        // throw error;
        console.warn('Error remove draft: ', error);
    }
}

export const sendFileDraft = async (draftId: string, file: File, type: string, attachmentType: 'photo'|'document'): Promise<any> => {
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    formData.append(`type`, type);
    formData.append(`attachmentType`, attachmentType);

    const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: ''
    };

    const token = sessionStorage.getItem('token');

    if (token !== '' && token !== null) {
        headers.Authorization = 'Bearer ' + token;
    }

    try {
        const result = await axios({
            method: 'POST',
            url: url + `claim-draft/${draftId}/attach-file`,
            data: formData,
            headers
        });

        return result;
    } catch (error) {
        // throw error;
        console.warn('Error remove draft: ', error);
    }

}

export const readDraftImage = async (key: string) => {
    const result = await getUrlOfImageAsync(key);
    if (result && !!result?.data?.url){
        return result.data.url;
    }
    return null;
}
