import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { StateT as ClaimDetailsStateT } from 'Store/modules/claimDetails';
import ClaimListDesktop from './Components/ClaimListDesktop/ClaimListDesktop';
import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import ClaimListMobile from './Components/ClaimListMobile/ClaimListMobile';
import { setOpenModalStoreSuccess, StateT } from 'Store/modules/page';
import type { StateT as ClaimListStateT } from 'Store/modules/claim';
import { resetClaimDetailsState } from 'Store/modules/claimDetails';
import { variableCheckModalStoreSuccess } from "Helpers/rules";
import type { StateT as UserStateT } from 'Store/modules/user';
import { openClaimChat } from "Store/modules/claimChat/data";
import { useDeviceType } from 'Helpers/responsiveContainers';
import type { AppStateT } from 'Store/modules';
import { getClaims } from "services/api";
import Regs from 'Helpers/regexps';

interface IUserSelector {
    user: UserStateT['data'];
    firstTimeLogin: UserStateT['firstTimeLogin'];
}

function usePageData(archive: boolean, isMobile: boolean) {
    const dispatch = useDispatch();
    const claimList = useSelector<AppStateT, ClaimListStateT>(({ claim }) => claim);
    const { reloadClaims } = useSelector<AppStateT, StateT|null>((state) => state.page);
    const claimDetails = useSelector<AppStateT, ClaimDetailsStateT>(({ claimDetails }) => claimDetails);
    const { user, firstTimeLogin } = useSelector<AppStateT, IUserSelector>(({ user }) => ({
        user: user.data,
        firstTimeLogin: user.firstTimeLogin
    }));
    const navigate = useNavigate();
    const {id: claimId, action } = useParams<any>();
    const isValidClaimId = useMemo(() => claimId && Regs.claimId.test(claimId), [claimId]);

    /*
     * Getting claim list
     **/
    useEffect(() => {
        getClaims(archive, user.id, dispatch);
    }, [dispatch, archive, reloadClaims, user.id]);

    useEffect(() => {
        if (isValidClaimId){
            dispatch(openClaimChat(claimId, archive, user.id));
        }
    }, [isValidClaimId, claimId, archive, dispatch, user.id]);

    useEffect(() => {
        if (!claimList.loading && !isMobile && !action){
            if (claimList.data.length > 0){
                if (isValidClaimId && claimList.data.some(cl => cl.id === claimId)){
                    return navigate(`/${archive ? 'archive/' : ''}${claimId}`);
                }
                navigate(`/${archive ? 'archive/' : ''}${claimList.data[0].id}`);
            } else {
                navigate(`/${archive ? 'archive/' : ''}`);
            }
        }
    }, [claimId, isValidClaimId, claimList.data, claimList.loading, isMobile, navigate, archive, action]);

    const openClaimDetails = useCallback((id: string) => {
        navigate(`/${archive ? 'archive/' : ''}${id}`);
    }, [archive, navigate]);

    const closeClaimDetails = useCallback(() => {
        dispatch(resetClaimDetailsState());
    }, [dispatch]);

    return {
        user,
        claimList,
        claimDetails,
        openClaimDetails,
        closeClaimDetails,
        firstTimeLogin,
    };
}

type MainPropsT = {
    archive?: boolean
}

const ClaimList: React.FC<MainPropsT> = (props) => {
    const { isMobile } = useDeviceType();
    const {
        user,
        claimList,
        claimDetails,
        openClaimDetails,
        closeClaimDetails,
        firstTimeLogin,
    } = usePageData(props.archive, isMobile);
    const { action } = useParams<any>();
    const { claim } = useSelector<AppStateT, Pick<ChatDataStateT, 'claim'>>(({ claimChat, claim }) => ({ claim: claimChat.data.claim }));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (action && action === variableCheckModalStoreSuccess){
            dispatch(setOpenModalStoreSuccess(true));
        }
    }, [action, dispatch, navigate]);

    return (
        <>
            {( isMobile ) ?
                <ClaimListMobile
                    user={user}
                    claimList={claimList}
                    claimDetails={claimDetails}
                    openClaimDetails={openClaimDetails}
                    closeClaimDetails={closeClaimDetails}
                    actionParam={action}
                    archive={props.archive}
                    claim={claim}
                />:
                <ClaimListDesktop user={user} claimList={claimList} firstTimeLogin={firstTimeLogin} actionParam={action} archive={props.archive} claim={claim} />
            }

            {/* <ErrorModal /> */}
        </>
    );
}

export default ClaimList;