import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'Components';

import { StateLocalT, TagNumbersAcceptedT } from '../../../CreateForm';
import RadioButton from '../../Summary/RadioButton/RadioButton';
import style from './ModalNextTag.module.scss';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

type MainProps = {
    show: boolean,
    actionClose: () => void,
    t: (data: string, param?: any) => string,
    tagNumbersAccepted: StateLocalT['tagNumbersAccepted'],
    actionNextTags: (data: StateLocalT['tagNumbersAccepted'], checkFoundBags?: boolean) => void,
    actionNewClaim: () => void,
}

const ModalNextTag: React.FC<MainProps> = ({ show, actionClose, t, tagNumbersAccepted, actionNextTags, actionNewClaim }) => {

    const [numberAccepted, setNumberAccepted] = useState<StateLocalT['tagNumbersAccepted']>([...tagNumbersAccepted]);
    const [lastTag, setLastTag] = useState<TagNumbersAcceptedT|null>(null);

    useEffect(() => {
        setNumberAccepted([...tagNumbersAccepted]);
        setLastTag(tagNumbersAccepted.filter(tag => tag.status !== 'done').length <=1 ? tagNumbersAccepted.find(t => t.status === 'processing') : null);
    }, [tagNumbersAccepted]);

    const actionSetTag = useCallback((tag: TagNumbersAcceptedT) => {
        setNumberAccepted(old => old.map(t => {
            if (t.id === tag.id){
                t.status = 'processing';
            } else  if (t.status === 'processing'){
                t.status = 'waiting';
            }
            return t;
        }))
    }, []);

    const actionConfirm = useCallback(() => {
        actionClose();
        actionNextTags([...numberAccepted], true);
        actionNewClaim();
    }, [numberAccepted, actionClose, actionNewClaim, actionNextTags]);

    return (
        <CustomModal open={show} >
            <CustomModalHeader onClose={actionClose} title={t('createClaim.finishSendLabel1')}/>
            {lastTag ?
                <>
                    <div className={style.nextLuggageContent}>
                        {t('createClaim.tagFinishPrepareLast')}
                    </div>
                    <div className={style.nextLuggageTagNumber}>
                        {lastTag.brand}{lastTag.tagNumber ? ` (${lastTag.tagNumber})` : ''}
                    </div>
                    <div className={style.buttons}>
                        <Button onClick={actionConfirm}>{t('createClaim.tagButtonAdd')}</Button>
                    </div>
                </>:
                <>
                    <div className={style.nextLuggageContent}>
                        {t('createClaim.tagHeaderAddNext')}
                        <ul>
                            {tagNumbersAccepted.map(tag => (
                                <li key={`tag_${tag.tagNumber}`}>
                                    <div className={style.line}>
                                        <RadioButton
                                            on={tag.status === 'processing'}
                                            action={() => actionSetTag(tag)}
                                            disabled={tag.status === 'done'}
                                        />
                                        <span onClick={() => tag.status !== 'done' ? actionSetTag(tag) : null}>
                                            {tag.brand}{tag.tagNumber ? ` (${tag.tagNumber})` : ''}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className={style.buttons}>
                            <Button onClick={actionConfirm}>{t('createClaim.tagButtonAdd')}</Button>
                        </div>
                    </div>
                </>
            }

        </CustomModal>
    );
}
export default ModalNextTag;