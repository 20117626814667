import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ReactComponent as ContactEmail } from 'Assets/contact_email.svg';
import { ReactComponent as IconChat } from 'Assets/chat.svg';

import {
  Button,
  CaseListDesktop,
  LoadableContent,
  MainScreenDesktop,
  SideBarDesktop,
  Tab,
} from 'Components';
import {
  ClaimDetailsDesktop,
  RepairDropOffLocation,
  RepairPickUpForm,
} from 'PageComponents/Chat';
import { CreateClaim, SaveDataAsDraftModal } from 'PageComponents/CreateClaim';
import { RESOLUTION_TYPE } from 'PageComponents/Chat/Chat/Chat.constants';
import { ReactComponent as Exclamation } from 'Assets/exclamation.svg';
import { ReactComponent as ChatBotIcon } from 'Assets/chat-bot.svg';
import { ReactComponent as CrossIcon } from 'Assets/cross-icon.svg';
import { ReactComponent as LeftArrow } from 'Assets/left-arrow.svg';
import BusinessCaseIcon from 'Assets/business-case.svg';

import { claimRepairDropOffRequest, resetRepairState } from 'Store/modules/claimChat/repair';
import { setLoadingClaims, type StateT as ClaimListStateT } from 'Store/modules/claim';
import { setClaimArchived, setMessageClaim, setShowContact } from "Store/modules/page";
import ClaimBlockedOnChat from "Components/ClaimBlockedOnChat/ClaimBlockedOnChat";
import type { StateT as ChatDataStateT } from 'Store/modules/claimChat/data';
import { StateT as ClaimDetailsStateT } from 'Store/modules/claimDetails';
import useChatConversationVisible from "Hooks/useChatConversationVisible";
import stylesChat from 'PageComponents/Chat/Chat/Chat.module.scss';
import { clearDraftData, useDraftData } from 'Helpers/draft';
import { type StateT } from 'Store/modules/claimChat/data';
import { ClaimChatStateT } from 'Store/modules/claimChat';
import LoadingCss from "Components/LoadingCss/LoadingCss";
import { ClaimProgressStageE } from "Store/Enums/claim";
import AlertInfo from 'Components/AlertInfo/AlertInfo';
import { setFirstTimeLogin } from 'Store/modules/user';
import styles from './ClaimListDesktop.module.scss';
import { getClosestWorkingDay } from 'Helpers/date';
import useSalutation from "Hooks/useSalutation";
import type { ClaimT } from 'api/modules/claim';
import type { AppStateT } from 'Store/modules';
import { userNameJoin } from 'Helpers/strings';
import Feed from "PageComponents/Feed/Feed";
import useKeyImage from "Hooks/useKeyImage";
import { UserT } from "api/modules/user";
import CustomModal from 'Components/CustomModal/CustomModal';

const OPEN_CASES_TAB = 'OPEN_CASES_TAB';
const CLOSED_CASES_TAB = 'CLOSED_CASES_TAB';

const saveToDraftResolutions = ['wireTransfer', 'paypal', 'pickUp'];

type MainPropsT = {
  user: UserT,
  claimList: ClaimListStateT,
  firstTimeLogin: boolean,
  actionParam: string,
  archive: boolean,
  claim: ClaimT | null,
}

const ClaimListDesktop: React.FC<MainPropsT> = ({ user, claimList, firstTimeLogin, actionParam, archive, claim }) => {
  const { t } = useTranslation();
  const [currentTab, setTab] = useState<string>(OPEN_CASES_TAB);
  const [createClaimModalOpen, setCreateClaimModalOpen] = useState<boolean>(false);
  const [saveDraftModalOpen, setSaveDraftModalOpen] = useState<boolean>(false);
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [resolution, setResolution] = useState<RESOLUTION_TYPE>(RESOLUTION_TYPE.NONE);
  const { chatMessages } = useSelector<AppStateT, StateT>((state) => state.claimChat.data);
  const [showAlertSpareParts, setShowAlertSpareParts] = useState<boolean>(!!actionParam && (actionParam === 'successSpareParts' || actionParam === 'errorSpareParts'));
  const enabledConversation = useChatConversationVisible(claim?.branch, claim?.status, chatMessages, user.language);

  const { loading } = useSelector<AppStateT, Pick<ChatDataStateT, 'loading'>>(({ claimChat }) => ({ loading: claimChat.data.loading }));

  const { draftData, setDraftData } = useDraftData(resolution);
  const { isModalOpen } = useSelector<AppStateT, ClaimDetailsStateT>(({ claimDetails }) => claimDetails);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const createClaimControlRef = useRef<{ close: () => void } | null>(null);
  const sideBarRef = useRef<{ close: () => void } | null>(null);

  const { repair, data } = useSelector<AppStateT, ClaimChatStateT>(({ claimChat }) => claimChat);
  const location = data.claim?.location;
  const repairLocation = repair.userLocation || location;
  const hasResolution = Boolean(data.resolution);
  const userTitle = useSalutation(user);
  const photoUrl = useKeyImage(claim);

  useEffect(() => {
    setShowAlertSpareParts(!!actionParam && (actionParam === 'successSpareParts' || actionParam === 'errorSpareParts'));
  }, [actionParam]);

  useEffect(() => {
    setTab(!archive ? OPEN_CASES_TAB : CLOSED_CASES_TAB)
  }, [archive]);

  const clearResolutionType = () => setResolution(RESOLUTION_TYPE.NONE);

  useEffect(() => {
    setDetailsOpen(isModalOpen);
  }, [dispatch, isModalOpen]);

  useEffect(() => {
    if (data.resolution && resolution) {
      clearResolutionType();
    }
  }, [data.resolution, resolution]);

  useEffect(() => {
    if (firstTimeLogin) {
      setCreateClaimModalOpen(true);
      dispatch(setFirstTimeLogin(false));
    }
  }, [dispatch, firstTimeLogin]);

  const setCurrentTab = useCallback((tab: string) => () => {
    dispatch(setLoadingClaims(true));
    navigate(`/${tab === CLOSED_CASES_TAB ? 'archive' : ''}`);
    dispatch(setClaimArchived(tab !== OPEN_CASES_TAB));
    setTab(tab);
  }, [dispatch, navigate]);

  const cases = useMemo(() => claimList?.data || [], [claimList]);

  useEffect(() => {
    if (repair.noParcelLocation) {
      setResolution(RESOLUTION_TYPE.PICK_UP);
    }
  }, [repair.noParcelLocation]);

  useEffect(() => {
    if (resolution === RESOLUTION_TYPE.NONE) {
      dispatch(resetRepairState());
    }
  }, [resolution, dispatch]);

  const repairDropOffSubmit = useCallback(() => {
    dispatch(claimRepairDropOffRequest(claim?.id));
  }, [dispatch, claim?.id]);

  const resolutionContent = useMemo(() => {
    switch (resolution) {
      case RESOLUTION_TYPE.REPAIR:
        // xxx, wymuszone przejscie do adresu dla kuriera
        return (
          <RepairPickUpForm
            loading={repair.loading}
            claimId={claim?.id}
            // onSubmit={repairPickUpSubmit}
            error={repair.error}
            initialValues={{
              name: (user?.firstName || '') + ' ' + (user?.lastName || ''),
              location: repairLocation as LocationT,
              date: getClosestWorkingDay(),
            }}
            sideBarRef={sideBarRef}
            close={clearResolutionType}
            openSaveASDraftModal={() => setSaveDraftModalOpen(true)}
            draftData={draftData}
            isBlocked={claim?.isBlocked}
          />
        );
      case RESOLUTION_TYPE.REPAIR_DROP_OFF:
        if (repair.location) {
          return (
            <RepairDropOffLocation
              onPickUpSelected={() => setResolution(RESOLUTION_TYPE.PICK_UP)}
              onDropOffSelected={repairDropOffSubmit}
              location={repair.location}
            />
          );
        }
        return null;

      case RESOLUTION_TYPE.PICK_UP:
        return (
          <RepairPickUpForm
            loading={repair.loading}
            claimId={claim?.id}
            error={repair.error}
            initialValues={{
              name: (user?.firstName || '') + ' ' + (user?.lastName || ''),
              location: repairLocation as LocationT,
              date: getClosestWorkingDay(),
            }}
            sideBarRef={sideBarRef}
            close={clearResolutionType}
            openSaveASDraftModal={() => setSaveDraftModalOpen(true)}
            draftData={draftData}
            isBlocked={claim?.isBlocked}
          />
        );

      default:
        return null;
    }
  }, [
    user,
    repair,
    resolution,
    claim?.id,
    repairDropOffSubmit,
    repairLocation,
    draftData,
    claim?.isBlocked
  ]);

  const openStore = useCallback(() => {
    if (!claim) {
      return;
    }

    navigate(`/store/${claim.id}`);
  }, [navigate, claim]);

  const setShowMessageCase = useCallback(() => {
    dispatch(setMessageClaim(claim))
  }, [dispatch, claim]);

  const navigationArrow = useMemo(() => {
    switch (resolution) {
      case RESOLUTION_TYPE.PAYPAL:
      case RESOLUTION_TYPE.BANK:
        return <LeftArrow onClick={() => setResolution(RESOLUTION_TYPE.CASH_REFUND)} />;
      case RESOLUTION_TYPE.REPAIR_DROP_OFF:
        return <LeftArrow onClick={() => setResolution(RESOLUTION_TYPE.REPAIR)} />;
      case RESOLUTION_TYPE.PICK_UP:
        const destination = repair.noParcelLocation
          ? RESOLUTION_TYPE.REPAIR
          : RESOLUTION_TYPE.REPAIR_DROP_OFF;
        return <LeftArrow onClick={() => setResolution(destination)} />;
      default:
        return null;
    }
  }, [resolution, repair]);

  const createClaimTitleData = useMemo(() => ({
    subtitle: t('createClaim.subtitleDesktop'),
  }), [t]);

  const selectClaim = useCallback(({ id }: ClaimT) => {
    navigate(`/${archive ? 'archive/' : ''}${id}`, { replace: true });
  }, [navigate, archive])

  const closeSideBar = () => {
    if (saveToDraftResolutions.includes(resolution)) {
      sideBarRef.current?.close();
    } else {
      clearResolutionType();
    }
  };

  const saveAsDraft = () => {
    if (sideBarRef) {
      const formValues = (sideBarRef.current as any).getFormValues();
      setDraftData(formValues);
      setSaveDraftModalOpen(false);
      clearResolutionType();
    }
  };

  const clearForm = () => {
    clearDraftData(resolution);
    setSaveDraftModalOpen(false);
    clearResolutionType();
  };

  return (
    <div className={styles.root}>
      <SideBarDesktop
        show={!resolution}
        containerClassName={styles.leftSidebarDesktop}
        animation="left"
      >
        <>
          <div className={styles.titleContainer}>
            <div>
              <ChatBotIcon height={45} width={45} />
            </div>
            <div className={styles.titleTextContainer}>
              <div className={styles.title}>
                {t('claimList.titleDesktop', { name: userNameJoin(user, userTitle.salutation, userTitle.title) })}
              </div>
              <div className={styles.subTitle}>{t('claimList.subtitleDesktop')}</div>
            </div>
          </div>
          <div className={styles.tabRow} id="tabClaims">
            <Tab
              onClick={setCurrentTab(OPEN_CASES_TAB)}
              active={currentTab === OPEN_CASES_TAB}
              className={styles.tab}
            >
              {t('claimList.openCasesTab')}
            </Tab>

            <Tab
              onClick={setCurrentTab(CLOSED_CASES_TAB)}
              active={currentTab === CLOSED_CASES_TAB}
              className={styles.tab}
            >
              {t('claimList.closedCasesTab')}
            </Tab>
          </div>
          <LoadableContent loading={claimList.loading}>
            {cases.length === 0 && (
              <>
                <div className={styles.noClaims}>
                  <img src={BusinessCaseIcon} alt="" />
                  {currentTab === OPEN_CASES_TAB ? (
                    <>
                      <span className={styles.noClaimsText}>{t('claimList.noOpenClaimP1')}</span>
                      <span className={styles.noClaimsText}>{t('claimList.noOpenClaimP2')}</span>
                    </>
                  ) : (
                    <span className={styles.noClaimsText}>{t('claimList.noClosedClaim')}</span>
                  )}
                </div>
                {currentTab === OPEN_CASES_TAB && (
                  <div className={styles.buttonContainer}>
                    <Button className={styles.button} onClick={() => setCreateClaimModalOpen(true)} id="button_new_claim_desktop">
                      {t('claimList.makeClaim')}
                    </Button>
                  </div>
                )}
              </>
            )}

            {cases.length >= 1 && (
              <>
                <CaseListDesktop activeCase={claim} onClickCase={selectClaim} data={cases} />
                <div className={styles.buttonPls} onClick={() => setCreateClaimModalOpen(true)} id="button_add_claim_desktop">
                  +
                </div>
              </>
            )}
          </LoadableContent>
        </>
      </SideBarDesktop>

      <MainScreenDesktop>
        {claim && (
          <div
            className={cx(styles.mainScreenDesktopContainer, {
              [styles.claimDetailsOpen]: isDetailsOpen,
            })}
          >
            <div className={styles.mainScreenDesktopContent}>
              <div className={styles.infoContainer}>
                <div className={styles.body}>
                  <div className={cx(styles.info, { [styles.infoLoading]: !photoUrl })}>
                    {photoUrl ?
                      <img src={photoUrl} alt="luggage" className={styles.img} /> :
                      <LoadingCss />
                    }
                  </div>
                  <div className={cx(styles.idNumber)}>{t('caseDetails.caseNumberLabel')}<p className={cx(styles.claimNumberBold)}>{claim.caseNumber}</p></div>
                </div>
                <div className={styles.icons}>
                  {(enabledConversation && !loading) ?
                    <div
                      className={cx(styles.chatIconContainer, { [styles.hasMessages]: !!chatMessages })}
                      onClick={() => setShowMessageCase()}
                      title={t('chatContact.placeholderIconChat')}
                    >

                      <div className={styles.messageChatIcon}>
                        {(chatMessages && chatMessages.unreadedMessagesByPax > 0) &&
                          <span className={styles.countMessages}>{chatMessages.unreadedMessagesByPax}</span>
                        }
                        <IconChat className={cx(styles.chatIco, { [styles.hasMessages]: chatMessages?.messages && Array.isArray(chatMessages?.messages) && chatMessages?.messages.length > 0 })} />
                      </div>
                      <span>{t('chatMobile.footerChat')}</span>

                    </div> :
                    <div
                      className={cx(styles.chatIconContainer, styles.contact)}
                      onClick={() => dispatch(setShowContact(claim.id))}
                      title={t('chatContact.placeholderIconChat')}
                    >
                      <ContactEmail className={styles.email} />
                      <span>{t('help_center.contact')}</span>
                    </div>
                  }
                  <div className={styles.exclamationIconContainer} onClick={() => setDetailsOpen(!isDetailsOpen)}>
                    <Exclamation className={styles.exclamationIco} />
                    <span>{t('chatMobile.footerDetails')}</span>
                  </div>
                </div>
              </div>

              {/* {(claim?.isBlocked && !(claim?.feed && claim.feed.entries.some(e => e.stage.indexOf('BLOCKED') >= 0))) ? */}
              {(claim?.isBlocked && !(claim?.feed && Array.isArray(claim.feed?.entries) && claim.feed.entries.some(entry => entry.stage === ClaimProgressStageE.REGISTERED))) ?
                <div className={cx(stylesChat.root, stylesChat.rootDesktop)}>
                  <div className={stylesChat.chatContainer} id="chatContainer">
                    <ClaimBlockedOnChat claim={claim} />
                  </div>
                </div>:
                <>
                  <Feed
                    key={claim.id}
                    claimId={claim.id}
                    onChooseResolution={(resolution: RESOLUTION_TYPE) => setResolution(resolution)}
                    actionParam={actionParam}
                    openStore={openStore}
                  />
                </>
              }

            </div>

            {isDetailsOpen && <ClaimDetailsDesktop onClose={() => setDetailsOpen(false)} claim={claim} />}
          </div>
        )}
      </MainScreenDesktop>

      {!hasResolution && (
        <SideBarDesktop
          show={!!resolution}
          containerClassName={styles.rightSideBarContainer}
          backdrop
          animation="right"
          closeOnClickOutside
          onClose={closeSideBar}
          headerTitle={() => (
            <div className={styles.sideBarTitle}>
              <div className={styles.leftIcon}>{navigationArrow}</div>
              {t(`resolutionModalTitles.${resolution}`)}
              <CrossIcon className={styles.crossIconHeader} onClick={closeSideBar} />
            </div>
          )}
        >
          <div className={styles.resolutionContent}>{resolutionContent}</div>
        </SideBarDesktop>
      )}
      <CustomModal
        open={createClaimModalOpen}
        onClose={() => {
          createClaimControlRef.current?.close();
        }}
        dialogStyle={{width:'460px', margin: 'auto'}}
      >
        <CreateClaim
          controlRef={createClaimControlRef}
          titleData={createClaimTitleData}
          onClose={() => setCreateClaimModalOpen(false)}
        />
      </CustomModal>
      <SaveDataAsDraftModal
        open={saveDraftModalOpen}
        onClose={() => setSaveDraftModalOpen(false)}
        onConfirm={saveAsDraft}
        onDeny={clearForm}
        withReopenChat
      />
      {showAlertSpareParts &&
        <AlertInfo
          actionClose={() => {
            setShowAlertSpareParts(false);
            navigate(`/${claim?.id}`, { replace: true });
          }}
          description={actionParam === 'successSpareParts' ? t('chatMessages.sparePartsModalSuccess') : t('chatMessages.sparePartsModalError')}
          type={actionParam === 'successSpareParts' ? 'success' : 'error'}
          isMobile={false}
        />
      }
    </div>
  );
}

export default ClaimListDesktop;
