import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "rsuite";
import { AppStateT } from "Store/modules";
import { setModalZIndex, incrementModalCount, decrementModalCount} from "Store/modules/page";

interface CustomModalProps {
  open: boolean;
  onClose?: () => void; 
  children: ReactNode; 
  size?: "xs" | "sm" | "md" | "lg" | "full";
  dialogStyle?: React.CSSProperties;
}

const CustomModal: React.FC<CustomModalProps> = (props) => {
  const { open, onClose, children, size, dialogStyle, ...rest } = props;

  const dispatch = useDispatch();
  const zIndex = useSelector<AppStateT, number>((state) => state.page.zIndex);
  const modalCount = useSelector<AppStateT, number>((state) => state.page.modalCount);

  useEffect(() => {
      if (open) {
          dispatch(incrementModalCount());
          dispatch(setModalZIndex(modalCount + 1));
      }
      return () => {
          if (open) {
              dispatch(decrementModalCount());
          }
      };
  }, [open, dispatch, modalCount]);

  return (
      <Modal
          open={open}
          onClose={onClose}
          backdropStyle={{ zIndex }}
          size={size}
          style={{ zIndex}}
          dialogStyle={dialogStyle}
          {...rest}
      >
          {children}
      </Modal>
  );
};

export default CustomModal;
