import cx from 'classnames';
import React from 'react';

import style from './InfoContent.module.scss';

type mainPropsT = {
    text: string,
    style?: string
}

const InfoContent: React.FC<mainPropsT> = ({ text, style: styleProps }) => {
    return (
        <div className={cx(style.root, styleProps)}>
            {text}
        </div>
    );
}
export default InfoContent;