import { CountdownCircleTimer } from "react-countdown-circle-timer";
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import Lottie from 'react-lottie';

import { ApiSendMethods } from "PageComponents/Feed/DataStructure/services";
import { useSocket } from "PageComponents/SocketContext/SocketContext";
import CustomModal from "Components/CustomModal/CustomModal";
import runApi, { ResultApiTypeT } from "api/base/runApi";
import animationErrror from 'Assets/lotties/error.json';
import { logout } from "Store/modules/authorization";
import style from './SessionCloseLogout.module.scss';
import useTranslate from "Hooks/useTranslate";
import Button from "Components/Button/Button";

const lottieOptions = {
    loop: true,
};

let timer: any = null;

const SessionCloseLogout: React.FC = () => {
    
    const t = useTranslate();
    const [open, setOpen] = useState(false);
    const [counterComplete, setCounterComplete] = useState(false);
    const { disconnect } = useSocket();
    const dispatch = useDispatch();

    const actionClose = useCallback(() => {
        setCounterComplete(true);
        runApi('user/logout', {}, (r: ResultApiTypeT) => {
            dispatch(logout());
            disconnect();
            setOpen(false);
        }, ApiSendMethods.get);
    }, [dispatch, disconnect]);

    useEffect(() => {
        timer = setTimeout(() => {
            setOpen(true);
        }, 500);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    return (
        <CustomModal open={open} onClose={actionClose}>
            <div className={style.root}>
                <div className={style.animation}>
                    <Lottie
                        options={{
                            ...lottieOptions, 
                            animationData: animationErrror
                        }} 
                        height={120} 
                        width={120}
                    />
                </div>
                <div className={style.text}>
                    {t('login.logoutSafeAuto')}
                </div>
                {!counterComplete &&
                    <>
                        <div className={style.countdown}>
                            <CountdownCircleTimer
                                isPlaying
                                // initialRemainingTime={initialVerificationCodeCounter}
                                duration={60}
                                colors={'#2fc3dd'}
                                size={50}
                                strokeWidth={5}
                                onComplete={actionClose}
                            >
                                {({ remainingTime }) => remainingTime }
                            </CountdownCircleTimer>
                        </div>
                        <div className={style.buttons} onClick={actionClose}>
                            <Button>{t('common.close')}</Button>
                        </div>
                    </>
                }
            </div>
        </CustomModal>
    );
}
export default SessionCloseLogout;