import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import cx from 'classnames';

import { ReactComponent as LuggageIcon } from 'Assets/luggage.svg';
import { ReactComponent as IconCalendar } from 'Assets/calendar2.svg';
import { ReactComponent as IconDownload } from 'Assets/download.svg';
import { ReactComponent as IconInfo } from 'Assets/info2.svg';
import { ReactComponent as IconRepair } from 'Assets/repair.svg';
import { ReactComponent as IconBrokenHeart } from 'Assets/brokenHeart.svg';

import useFormattedPrice from "Hooks/useFormattedPrice";
import { makeSlug, setLuggageTypeTitleLang } from "Helpers/strings";
import { ClaimFileT, ClaimT } from "api/modules/claim";
import { formatDateSaleforce } from "Helpers/date";
import { PageConfigT } from 'Store/modules/page';
import style from './ClaimDetails.module.scss';
import { getUrlOfImage, getUrlOfImages } from "services/api";
import historyStatus from 'Hooks/useHistory';
import { AppStateT } from "Store/modules";
import { CaseStatus } from "Components";

type MainPropsT = {
    claim: ClaimT,
    t: (data: string, param?: any) => string,
}

type ClaimVerdictT = {
    value?: number,
    verdictType?: string
}

const ClaimDetails: React.FC<MainPropsT> = ({ claim, t }) => {

    const [photos, setPhotos] = useState<ClaimFileT[]>(claim.case.files.photos);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);
    const formatPrice = useFormattedPrice(claim.luggage.currencyIsoCode, pageConfig.noFractionCurrencies);
    const claimHistory = historyStatus(claim);
    const [photoKeys, setPhotoKeys] = useState<{ key: string, url: string }[]>([]);
    const [isValidPdfUrl, setIsValidPdfUrl] = useState<boolean>(false);

    useEffect(() => {
        getUrlOfImages(!!claim && claim.case?.files?.photos ? claim.case.files.photos.map(cf => cf.thumbnail?.key) : [], (result, dataResult) => result ? setPhotoKeys(dataResult) : setPhotoKeys([]));
    }, [claim]);

    useEffect(() => {
        setPhotos(claim.case.files.photos);
    }, [claim.case.files.photos]);

    useEffect(() => {
        if (claim?.pdfRaport && claim.pdfRaport.key) {
            getUrlOfImage(claim?.pdfRaport?.key, (result: boolean, url: string|undefined) => {
                if (result && url) {
                    setIsValidPdfUrl(true);
                } else {
                    setIsValidPdfUrl(false);
                }
            })
        } else {
            setIsValidPdfUrl(false);
        }
    }, [claim?.pdfRaport])

    const translateType = useCallback(() => {
        const slug = makeSlug(claim.luggage.type);
    
        return t(setLuggageTypeTitleLang(slug));
    }, [t, claim.luggage.type])

    const lastTruthyVerdict = useMemo(() => {
        let lastTruthy: ClaimVerdictT | undefined = undefined;
        if (!claimHistory) {
            return lastTruthy;
        }
        for (let i = claimHistory.length - 1; i >= 0; i -= 1) {
            const currHistPoint = claimHistory[i];
            if (currHistPoint.verdict) {
                lastTruthy = currHistPoint.verdict as ClaimVerdictT;
                break;
            }
        }
        return lastTruthy;
    }, [claimHistory]);

    const actionDownload = useCallback((pdfKey?: string) => {
        if (!pdfKey) {
            console.warn('PDF key is missing');
            return;
        }

        getUrlOfImage(pdfKey, (result: boolean, url: string|undefined) => {
            if (result && url) {
                window.open(url, '_self', 'noopener noreferrer');
            } else {
                console.error('Error while trying to download PDF');
            }
        })
    }, []);

    const handleClick = useCallback(() => {
        if (!claim?.pdfRaport?.key) {
            console.warn('PDF report is not available');
            return;
        }
        actionDownload(claim.pdfRaport.key);
    }, [claim?.pdfRaport?.key, actionDownload]);

    const formattedValue = useCallback(() => {
        if (!lastTruthyVerdict?.value) {
          return 'N/A';
        }
    
        return parse(t('common.valueWithCurrency', {
          currency: `<span class="price">${claim.luggage?.currencyIsoCode || ''}</span>`,
          value: `<span class="price">${formatPrice(lastTruthyVerdict.value)}</span>`,
        }));
    }, [lastTruthyVerdict?.value, t, claim.luggage?.currencyIsoCode, formatPrice]);
    
    return (
        <div className={style.root}>
            {photos.length > 0 &&
                <div className={style.photosLayer}>
                    <div className={style.label}>{t('chatMobile.chatPhotos')}</div>
                    <div className={style.photos}>
                        {photos.map(photo => {
                            
                            const photoUrl = photoKeys.find(pk => pk?.key === photo?.thumbnail?.key)?.url || '';

                            return (
                                <div className={style.photo} key={`photo-${photo?.thumbnail?.key}`} style={ { backgroundImage: `url(${photoUrl || ''})` }} />
                            )
                        })}
                    </div>
                </div>
            }
            <div className={cx(style.dataLayer, style.top)}>
                <div className={style.ico}>
                    <IconCalendar />
                </div>
                <div className={style.data}>
                    <div className={style.label}>{t('chatMobile.chatRegisterDate')}</div>
                    <div className={style.value}>{formatDateSaleforce(new Date(claim.createdAt))}</div>
                </div>
            </div>
            {claim.referenceNumber &&
                <div className={style.dataLayer}>
                    <div className={style.ico}>
                        <IconInfo />
                    </div>
                    <div className={style.data}>
                        <div className={style.label}>{t('createClaim.ref')}</div>
                        <div className={style.value}>{claim.referenceNumber}</div>
                    </div>
                </div>
            }
            {isValidPdfUrl &&
                <div
                    className={cx(style.dataLayer, style.dataLink)}
                    onClick={handleClick}
                    role={'button'}
                    tabIndex={0}
                >
                    <div className={style.ico}>
                        <IconDownload />
                    </div>
                    <div className={style.data}>
                        <div className={style.value}>{t('createClaim.finishDownloadPdf')}</div>
                    </div>
                </div>
            }
            <div className={style.dataLayer}>
                <div className={style.ico}>
                    {lastTruthyVerdict?.verdictType === 'irreparable' ? <IconBrokenHeart /> : <IconRepair />}
                </div>
                <div className={style.data}>
                    <div className={style.label}>{t('chatMobile.chatStatus')}</div>
                    <div className={style.value}>
                        {lastTruthyVerdict?.verdictType ? t(`verdictTypes.${lastTruthyVerdict?.verdictType}`) : 'N/A'}
                    </div>
                </div>
            </div>
            <div
                className={cx(style.dataLayer, isValidPdfUrl && style.dataLink)}
                onClick={isValidPdfUrl ? handleClick : undefined}
                role={isValidPdfUrl ? 'button' : undefined}
                tabIndex={isValidPdfUrl ? 0 : undefined}
            >
                <div className={style.ico}>
                    <LuggageIcon />
                </div>
                <div className={style.data}>
                    <div className={style.label}>{t('chatMobile.chatQuotation')}</div>
                    <div className={style.value}>
                        {formattedValue()}
                    </div>
                </div>
            </div>
            <div className={style.dataLayer}>
                <div className={style.value}>{t('chatMobile.chatLuggageAs')} <strong>{translateType()}</strong></div>
            </div>
            <div className={style.history}>
                <CaseStatus />
            </div>
        </div>
    );
}
export default React.memo(ClaimDetails);