import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStateT } from 'Store/modules';

import cx from 'classnames';

import { ReactComponent as IcoEdit } from 'Assets/info-pencil.svg';

import { generateId, makeSlug, setLuggageTypeTitleLang, setMaterialTitleLang, userNameJoin } from 'Helpers/strings';
import { IImage } from 'PageComponents/Chat/ReportUploadBox/ReportUploadBox';
import { formatDateMonthSimple, formatDateSaleforce } from 'Helpers/date';
import type { StateT as UserStateT } from 'Store/modules/user';
import { searchAirlineById, sizeAdapter } from 'Helpers/claim';
import useFormattedPrice from 'Hooks/useFormattedPrice';
import { changeModalTooltip } from 'Store/modules/page';
import RadioButton from './RadioButton/RadioButton';
import { escapeSelector } from 'Helpers/strings';
import { PageConfigT } from 'Store/modules/page';
import useSalutation from "Hooks/useSalutation";
import { StateLocalT } from '../../CreateForm';
import TextValue from './TextValue/TextValue';
import { StepsE } from 'Store/Enums/claim';
import style from './Summary.module.scss';

type MainProps = {
    setEditSlide: (indexSlug: string, detail?: string) => void,
    updateState: (dataType: string, data: any) => void,
    setValidStep: (result: boolean) => void,
    state: StateLocalT,
}

const Summary: React.FC<MainProps> = ({ state, setValidStep, updateState, setEditSlide }) => {

    const user = useSelector<AppStateT, UserStateT['data']>(({ user }) => user.data);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userTitle = useSalutation(user);
    const pageConfig = useSelector<AppStateT, PageConfigT>((state) => state.page.pageConfig);

    useLayoutEffect(() => {
        if (state.viewSlideSummaryType) {
            const sanitiziedType = escapeSelector(state.viewSlideSummaryType);
            const element = document.querySelector(`#slide-${sanitiziedType}`);

            if (element){
                element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
                updateState('viewSlideSummaryType', null);
            }
        }
    }, [state.viewSlideSummaryType, updateState]);

    const formatPrice = useFormattedPrice(state.claimData.details.currency, pageConfig.noFractionCurrencies)

    useEffect(() => {
        setValidStep(state.termsConcentData && state.termsConditions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.termsConcentData, state.termsConditions]);

    const goEdit = useCallback((slug: string, detail?: string) => {
        setEditSlide(slug, detail);
    }, [setEditSlide])

    const toolTipInformation = useCallback((type: 'terms'|'privacy') => {
        if (type === 'terms'){
            return dispatch(changeModalTooltip({ title: 'footer.termsAndConditions', pagesContentKey: 'regs.terms' }));
        }
        dispatch(changeModalTooltip({ title: 'footer.privacySettings', pagesContentKey: 'regs.privacy' }));
    }, [dispatch])

    const isStepOpened = useCallback((type: string): boolean => {
        return state.steps.some(st => st.type === type && !st.optional);
    }, [state.steps]);

    const getColorName = useCallback((color: string): string => {
        switch(color.toLowerCase()){
            case 'black': return t('createClaim.colorBlack');
            case 'white': return t('createClaim.colorWhite');
            case 'grey': return t('createClaim.colorGrey');
            case 'blue': return t('createClaim.colorBlue');
            case 'red': return t('createClaim.colorRed');
            case 'violet': case 'purple': return t('createClaim.colorViolet');
            case 'yellow': return t('createClaim.colorYellow');
            case 'beigne': case 'beige': return t('createClaim.colorBeige');
            case 'brown': return t('createClaim.colorBrown');
            case 'green': return t('createClaim.colorGreen');
            case 'multi-coloured': case 'multiColoured': case 'multi color': case 'multicolor': case 'multi': return t('createClaim.colorMulti');
            case 'pattern': return t('createClaim.colorPattern');

            default: return color;
        }
    }, [t]);

    const getStepType = (data: string) => {
        switch(data){
            case 'missed_AirlineAuthorisation': return StepsE.AIRLINES_AUTHORISATION_LETTER_COPY;
            case 'missed_Tag': return StepsE.TAG_NUMBER;
            case 'missed_BookingConfirmation': return StepsE.BOOKING_CONFIRMATION;
            case 'missed_Dpr': return StepsE.DPR_COPY;
            case 'missed_RndCopy': return StepsE.RND_COPY;
            case 'missed_EmailFromAirline': return StepsE.EMAIL_FROM_AIRLINE;
            case 'missed_Eticket': return StepsE.ETICKET;
            case 'missed_FlightTicket': return StepsE.FLIGHT_TICKET;
            case 'missed_PurchaseReceipt': return StepsE.PURCHASE_RECEIPT_COPY;
            case 'missed_StampedLeaflet': return StepsE.STAMPED_LEAFLET;
            case 'missed_Warranty': return StepsE.WARRANTY_CARD_COPY;
            case 'missed_AirlineProcessingNumber': return StepsE.AIRLINE_PROCESSING_NUMBER;
            case 'missed_DamageReport': return StepsE.DAMAGE_RAPORT;
            case 'missed_Haqiba': return StepsE.HAQIBA_COPY;
            case 'missed_PermisionFromAirline': return StepsE.PERMISSION_FROM_AIRLINE;
        }

        return data;
    }

    const getTitle = (label: string): string => {
        switch (label){
            case 'front': return t('createClaim.sumFront');
            case 'back': return t('createClaim.sumBack');
            case 'top': return t('createClaim.damageTop');
            case 'bottom': return t('createClaim.damageBottom');
            case 'left': return t('createClaim.damageLeft');
            case 'right': return t('createClaim.damageRight');

            case 'missed_AirlineAuthorisation': return t('createClaim.missAirlineAuthorisation');
            case 'missed_Tag': return t('createClaim.missTagunumber');
            case 'missed_BookingConfirmation': return t('createClaim.missBookingConfirmation');
            case 'missed_RndCopy': return t('createClaim.missedRndCopy');
            case 'missed_EmailFromAirline': return t('createClaim.missEmailFromAirline');
            case 'missed_Eticket': return t('createClaim.missEticket');
            case 'missed_FlightTicket': return t('createClaim.missFlightTicket');
            case 'missed_PurchaseReceipt': return t('createClaim.missPurchaseReceipt');
            case 'missed_StampedLeaflet': return t('createClaim.missStampedLeaflet');
            case 'missed_Warranty': return t('createClaim.missWarranty');
            case 'missed_AirlineProcessingNumber': return t('createClaim.missAirlineProcessingNumber');
            case 'missed_DamageReport': return t('createClaim.missDamageReport');
            case 'missed_Haqiba': return t('createClaim.missHaqiba');
            case 'missed_PermisionFromAirline': return t('createClaim.missPermisionFromAirline');
        }
        return label;
    }

    const setluggageTypeTitle = useMemo(() => t(setLuggageTypeTitleLang(makeSlug(state.claimData.luggageType))), [t, state.claimData.luggageType])

    return (
        <div className={style.root}>

            <div className={style.header}>
                <div className={style.nr}>1</div>
                <div className={style.text}>{t('createClaim.sumBase')}</div>
            </div>

            <div className={style.dataBlock}>
                <TextValue label={t('createClaim.sumName')} stepIndex='contact' value={userNameJoin(user, userTitle.salutation, userTitle.title)} />
                {state.claimData.referenceNumber &&
                    <TextValue label={t('createClaim.ref')} stepIndex='reference' value={state.claimData.referenceNumber} action={state.claimData.referenceNumber ? goEdit : undefined} />
                }
            </div>

            {(isStepOpened('airline')) &&
                <>
                    <div className={style.header}>
                        <div className={cx(style.nr, style.nrEmpty)} />
                        <div className={style.text}>{t('createClaim.sumFlight')}</div>
                    </div>

                    <div className={style.dataBlock}>
                        <TextValue label={t('createClaim.sumAirlineName')} stepIndex='airline' value={searchAirlineById(state.airlines, state.claimData.airlineId)} action={goEdit} />
                        <TextValue label={t('createClaim.airlineDate')} stepIndex='airline' value={state.claimData.airlineDateLanding ? formatDateSaleforce(state.claimData.airlineDateLanding) : ''} action={goEdit} />
                    </div>
                </>
            }

            <div className={style.header}>
                <div className={style.nr}>2</div>
                <div className={style.text}>{t('createClaim.sumBaggageGeneral')}</div>
            </div>

            <div className={style.dataBlock}>
                <TextValue label={t('createClaim.sumType')} stepIndex='typeof' value={setluggageTypeTitle} action={goEdit} />
                <TextValue label={t('createClaim.sumColor')} stepIndex='color' value={getColorName(state.claimData.luggageColor)} color={state.claimData.luggageColor !== 'MULTI' && state.claimData.luggageColor !== 'PATTERN' ? state.claimData.luggageColor : undefined} action={goEdit} />
                <TextValue label={t('createClaim.sumMaterial')} stepIndex='material' value={state.claimData.luggageMaterial ? t(setMaterialTitleLang(makeSlug(state.claimData.luggageMaterial))) : '-'} action={goEdit} />
            </div>

            <div className={style.header} id={`slide-${StepsE.DETAILS}`}>
                <div className={style.nr}>3</div>
                <div className={cx(style.text, style.textIco)}>
                    {t('createClaim.sumBaggage')}
                </div>
            </div>

            <div className={style.dataBlock}>
                <TextValue label={t('createClaim.detBrand')} stepIndex='details' value={state.claimData.details.brand || '-'} action={goEdit} />
                <TextValue label={t('createClaim.detModel')} stepIndex='details' value={state.claimData.details.model || '-'} action={goEdit} />
                {state.claimData.details.serialNumber &&
                    <TextValue label={t('createClaim.detSerialNumber')} stepIndex='details' value={state.claimData.details.serialNumber} action={goEdit} />
                }
                {state.claimData.details.size &&
                    <TextValue label={t('shop.size')} stepIndex='details' value={sizeAdapter(state.claimData.details.size, user.location.countryCode, user.language)} action={goEdit} />
                }
                {state.claimData.details.date &&
                    <TextValue label={t('createClaim.detDate')} stepIndex='details' value={formatDateMonthSimple(state.claimData.details.date)} action={goEdit} />
                }
                {+state.claimData.details.cost > 0 &&
                    <TextValue label={t('createClaim.detCost')} stepIndex='details' value={`${formatPrice(state.claimData.details.cost)} ${state.claimData.details.currency}`} action={goEdit} />
                }

                {/* {(sizesShow === 'length' || sizesShow === 'full') &&
                    <TextValue label={t('createClaim.detLength')} stepIndex='details' value={state.claimData.details.length > 0 ? `${state.claimData.details.length}${t('common.cm')}` : '-'} />
                }
                {(sizesShow === 'height' || sizesShow === 'full') &&
                    <TextValue label={t('createClaim.detHeight')} stepIndex='details' value={state.claimData.details.height > 0 ? `${state.claimData.details.height}${t('common.cm')}` : '-'} />
                }
                {sizesShow === 'full' &&
                    <TextValue label={t('createClaim.detWidth')} stepIndex='details' value={state.claimData.details.width > 0 ? `${state.claimData.details.width}${t('common.cm')}` : '-'} />
                }
                {sizesShow === 'capacity' &&
                    <TextValue label={t('createClaim.detCapacity')} stepIndex='details' value={state.claimData.details.capacity > 0 ? `${state.claimData.details.capacity}${t('common.cm')}` : '-'} />
                }
                {sizesShow === 'functions' &&
                    <TextValue label={t('createClaim.detFunctions')} stepIndex='details' value={`${state.claimData.details.functions}`} />
                } */}

                {/* {state.steps.some(step => step.type === 'tag' && !step.optional) &&
                    <TextValue label={t('createClaim.sumTag')} stepIndex='tag' value={state.claimData.tagNumber} action={goEdit} />
                } */}
            </div>

            <div className={style.header}>
                <div className={style.nr}>4</div>
                <div className={style.text}>{t('createClaim.sumPhotos')}</div>
            </div>

            {/* <PhotoSingle photos={state.claimData.photos} photoName={'flightTicket'} goEdit={goEdit} title={t('createClaim.sumTicket')} /> */}

            <div className={cx(style.header, style.headerPhotos)} id={`slide-${StepsE.LUGGAGE}`}>
                <div className={cx(style.text, style.textIco)}>
                    {t('createClaim.sumPhotoLuggage')}
                    <InfoEdit action={() => goEdit(StepsE.LUGGAGE)} className={style.detailHeaderAction} />
                </div>
            </div>

            <div className={cx(style.dataBlock, style.dataBlockPhotos, (Object.entries(state.claimData.photos).filter(data => !!state.claimData.photos[data[0]]).length > 1 ? style.dataBlockPhotos2 : style.dataBlockPhotos1))}>
                {Object.entries(state.claimData.photos).filter(data => !Array.isArray(data[1]) && !!state.claimData.photos[data[0]] && data[0].indexOf("missed_") <0).map(data => {
                    const _file = data[1] as IImage;
                    return (
                        <div className={style.photo} style={{ backgroundImage: `url(${_file.url})` }} key={`dmg_${_file.url}`}>
                            <div className={style.photoLabel}>
                                {getTitle(data[0])}
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* xxx */}
            {Object.entries(state.claimData.photos).filter(data => data[0].indexOf("missed_") === 0 && !!state.claimData.photos[data[0]]).map(data => {
                const _files = data[1] as IImage[];
                if (Array.isArray(_files)){

                    // console.log('data[0]: ', data[0]);
                    const doc = state.claimData.missingDocuments.find(m => _files.some(_f => _f.url === m.document.url));
                    if (!doc) return <React.Fragment key={`empty-${data[0]}${generateId()}`}></React.Fragment>;

                    const mainKey = `k1-${doc.document.name}${generateId()}`;
                    return <React.Fragment key={mainKey}>
                        <div className={cx(style.header, style.headerPhotos)} id={`slide-${doc.requireDocument.Missing_Document_Type__c.replaceAll(' ','-')}`}>
                            <div className={cx(style.text, style.textIco)}>
                                {doc.requireDocument.Name}
                                <InfoEdit action={() => goEdit(doc.requireDocument.Missing_Document_Type__c)} className={style.detailHeaderAction} />
                            </div>
                        </div>
                        <div className={cx(style.dataBlock, style.dataBlockPhotos, { [style.dataBlockPhotos2]: _files.length > 1, [style.dataBlockPhotos1]: _files.length === 1 })}>                            
                            {_files.map(f => <div className={style.photo} style={{ backgroundImage: `url(${f.url})` }} key={`${mainKey}-dmgx_${f.url}-${generateId()}`} />)}
                        </div>
                    </React.Fragment>
                }
                const _file = data[1] as IImage;
                return (
                    <React.Fragment key={`missPhoto_${data[0]}${generateId()}`}>
                        <div className={cx(style.header, style.headerPhotos)}>
                            <div className={cx(style.text, style.textIco)}>
                                {getTitle(data[0])}
                                <InfoEdit action={() => goEdit(getStepType(data[0]))} className={style.detailHeaderAction} />
                            </div>
                        </div>
                        <div className={style.photoDocument}>
                            <div className={style.photo} style={{ backgroundImage: `url(${_file.url})` }} key={`dmg_${_file.url}`} />
                        </div>
                    </React.Fragment>
                )
            })}

            <div className={cx(style.header, style.headerPhotos)} id={`slide-${StepsE.DAMAGES}`}>
                <div className={cx(style.text, style.textIco)}>
                    {t('createClaim.sumDamage')}

                    <InfoEdit action={() => goEdit(StepsE.DAMAGES)} className={style.detailHeaderAction} />
                </div>
            </div>

            <div className={cx(style.dataBlock, style.dataBlockPhotos, (state.claimData.photoDamages.length > 1 ? style.dataBlockPhotos2 : style.dataBlockPhotos1))}>
                {state.claimData.photoDamages.map(photo => (
                        <div className={style.photo} style={{ backgroundImage: `url(${photo.url})` }} key={`dmg_${photo.url}`} />
                ))}
            </div>

            {/* {state.requiredDocuments.some(doc => doc.dataType__c === MissingBaseTypesE.FILE && doc.file) && */}
            {state.requiredMissingDocuments.some(doc => doc.Url__c && doc.Obligatory__c) &&
                <>
                    <div className={cx(style.header, style.headerPhotos)} id={`slide-${StepsE.MISSING_DOCUMENTS}`}>
                        <div className={cx(style.text, style.textIco)}>
                            {t('createClaim.missingDocuments')}
                            <InfoEdit action={() => goEdit(StepsE.MISSING_DOCUMENTS)} className={style.detailHeaderAction} />
                        </div>
                    </div>
                    {/* {state.requiredDocuments.filter(doc => doc.dataType__c === MissingBaseTypesE.FILE).map(req => { */}
                    {state.requiredMissingDocuments.filter(doc => doc.Obligatory__c && doc.Url__c).map(req => {
                        const documents = state.claimData.missingDocuments.filter(data => data.requireDocument.Name === req.Name);
                        return (
                            <div  key={`mi${req.Id}_${req.dataType__c}`} className={style.missingFilesBody}>
                                <div className={style.missingFilesName}>{req.Name}</div>
                                <div className={style.missingFiles}>
                                    {documents.map(doc => (
                                        <div className={style.missingFile} key={`file_${doc.document.url}`}>
                                            <img src={doc.document.url} alt="" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </>
            }

            <div className={style.header} id={`slide-${StepsE.CONTACT}`}>
                <div className={style.nr}>5</div>
                <div className={cx(style.text, style.textIco)}>
                    {t('createClaim.sumContact')}
                </div>
            </div>

            <div className={style.dataBlock}>
                <TextValue label={t('labels.country')} stepIndex='contact' value={state.claimData.contact.country.name} action={goEdit}/>
                <TextValue label={t('labels.city')} stepIndex='contact' value={state.claimData.contact.city} action={goEdit}/>
                <TextValue label={t('labels.address')} stepIndex='contact' value={state.claimData.contact.address} action={goEdit}/>
                <TextValue label={t('labels.zip')} stepIndex='contact' value={state.claimData.contact.zipCode} action={goEdit}/>
            </div>

            <div className={cx(style.header, style.headerMargin)}>
                <div className={style.text}>{t('createClaim.sumContactLabel')}</div>
            </div>

            <div className={style.dataBlock}>
                <TextValue label={t('createClaim.sumEmail')} stepIndex='contact' value={user?.email || ''} />
                <TextValue label={t('createClaim.sumPhone')} stepIndex='contact' value={state.claimData.contact.phone} action={goEdit}/>
            </div>

            <div className={style.terms}>
                <div className={style.termsLine}>
                    <RadioButton on={state.termsConditions} action={() => updateState('termsConditions', !state.termsConditions)} id={'checkbox_new_claim_reg'} />
                    <div className={style.text}>{t('createClaim.sumAccept')} <span onClick={() => toolTipInformation('terms')}>{t('createClaim.sumTerms')}</span></div>
                </div>
                <div className={style.termsLine}>
                    <RadioButton on={state.termsConcentData} action={() => updateState('termsConcentData', !state.termsConcentData)} id={'checkbox_new_claim_terms'} />
                    <div className={style.text}>{t('createClaim.sumConsent')} <span onClick={() => toolTipInformation('privacy')}>{t('createClaim.sumConsentData')}</span></div>
                </div>
            </div>

        </div>
    )
}

type IcoProps = {
    action: () => void,
    className?: any
}

export const InfoEdit: React.FC<IcoProps> = ({ action, className }) => {
    return (
        <div className={cx(style.ico, className)} onClick={action}><IcoEdit /></div>
    )
}

export default React.memo(Summary);