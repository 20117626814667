import Lottie from 'react-lottie';
import cx from 'classnames';
import React from 'react';

import { useDeviceType } from "Helpers/responsiveContainers";
import animationSuccess from 'Assets/lotties/loading.json';
import style from './ModalWaitRedirectShop.module.scss';
import useTranslate from "Hooks/useTranslate";
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

const lottieOptions = {
    loop: true,
};

const ModalWaitRedirectShop: React.FC = () => {

    const { isMobile } = useDeviceType();
    const t = useTranslate();

    return (
        <CustomModal open>
            <div className={cx(style.root, { [style.mobile]: isMobile })}>
                <div className={style.body}>
                    <div className={style.content}>
                        <CustomModalHeader onClose={() => null} />    
                        <Lottie
                            options={{
                                ...lottieOptions, 
                                animationData: animationSuccess
                            }} 
                            height={120} 
                            width={120}
                        />
                        <div className={style.contentText}>
                            {t('shop.modalWaitingRedirectShop')}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}
export default React.memo(ModalWaitRedirectShop);