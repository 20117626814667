import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import React from 'react';

import animationSuccess from 'Assets/lotties/success.json';
import animationErrror from 'Assets/lotties/error.json';
import Button from 'Components/Button/Button';
import style from './AlertInfo.module.scss';
import { Modal } from 'rsuite';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

const lottieOptions = {
    loop: false,
};
  
type MainPropsT = {
    actionClose: () => void,
    type: 'success'|'error',
    description: string,
    isMobile?: boolean
}

const AlertInfo: React.FC<MainPropsT> = ({ actionClose, description, type, isMobile }) => {

    const { t } = useTranslation();

    return (
        <CustomModal open >
            <CustomModalHeader 
                title = {<div className={style.description}>{description}</div>} 
                onClose={actionClose}>     
            </CustomModalHeader>
            <Modal.Body> 
                <div className={style.root}>          
                    <div className={style.lottie}>
                        <Lottie
                            options={{
                                ...lottieOptions, 
                                animationData: type === 'success' ? animationSuccess : animationErrror
                            }} 
                            height={120} 
                            width={120}
                        />
                    </div>
                    <div className={style.buttons}>
                        <Button color={'primary'} onClick={actionClose}>
                            {t('common.close')}
                        </Button>
                    </div>
                </div>   
            </Modal.Body>
        </CustomModal>
    );
}
export default AlertInfo;