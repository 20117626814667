export const getSystemLanguage = (): { code: string, codeUpperCase: string, codeLowerCase: string, fullCode: string } => {

    const defaultResult = { code: 'en', codeUpperCase: 'US', codeLowerCase: 'us', fullCode: 'en-us' };

    if (window.navigator?.language){
        const dataLanguage = window.navigator.language.split('-');
        if (dataLanguage[1]){
            return { code: dataLanguage[0], codeUpperCase: dataLanguage[1], codeLowerCase: dataLanguage[1].toLowerCase(), fullCode: window.navigator.language };
        }
        return { code: window.navigator.language, codeUpperCase: window.navigator.language.toUpperCase(), codeLowerCase: window.navigator.language.toLowerCase(), fullCode: window.navigator.language };
    }

    return defaultResult;

}

export const isFirefox = (): boolean => navigator.userAgent.indexOf('Firefox') > -1;
export const isChrome = (): boolean => navigator.userAgent.indexOf('Chrome') > -1;

export const iOS = () => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const iPhone = (): boolean => {
    return navigator.userAgent.indexOf('iPhone') >=0;
}

export const isSafari = (): boolean => navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

export enum LocaleMap {
    'en-us' = 'en_US',
    'de' = 'de_DE',
    'pl' = 'pl_PL',
    'cn' = 'zh',         
    'cs-cz' = 'cs',     
    'es' = 'es_ES',      
    'hu' = 'hu',       
    'nl' = 'nl_NL',     
    'pt-pt' = 'pt_PT', 
}

const formatLanguage = (language: string): string => {
    return LocaleMap[language as keyof typeof LocaleMap] || LocaleMap['en-us'];
};

export const actionShowCookies = (actualLanguage: string): void => {
    const formattedLanguage = formatLanguage(actualLanguage);

    try {
        // @ts-ignore
        CCM.openWidget();

        if (formattedLanguage) {
            // @ts-ignore
            CCM.switchLocale(formattedLanguage);
        }
    } catch (error) {
        console.error('Error in cookies action:', error);
    }
};
