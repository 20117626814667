import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'Components';
import parse from 'html-react-parser';

import RadioButton from '../Steps/Summary/RadioButton/RadioButton';
import { FoundedBagT, StateLocalT } from '../CreateForm';
import style from './ModalTagNumbers.module.scss';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

type MainProps = {
    show: boolean,
    actionClose?: () => void,
    tagNumbers: FoundedBagT[],
    actionSetTags: (data: StateLocalT['tagNumbersAccepted']) => void,
    t: (data: string, param?: any) => string
}

type TagCheckedT = { tagNumber: string, checked: boolean, brand: string, color: string, id: string };

const ModalTagNumbers: React.FC<MainProps> = ({ show, actionClose, tagNumbers, actionSetTags, t }) => {

    const [tn, setTn] = useState<TagCheckedT[]>([]);
    const [tagNumbersAccepted, setTagNumbersAccepted] = useState<StateLocalT['tagNumbersAccepted']>([]);

    useEffect(() => {
        if (tagNumbers && Array.isArray(tagNumbers) && tagNumbers.length > 0){
            setTn(tagNumbers.map(tg => {
                return { tagNumber: tg.tagNumber, checked: true, brand: tg.brand, color: tg.color, id: tg.id }
            }));
        } else {
            setTn([]);
        }
    }, [tagNumbers]);
    
    useEffect(() => {
        const dataResult: StateLocalT['tagNumbersAccepted'] = [];
        tagNumbers.forEach((data, idx) => {
            dataResult.push({ 
                status: dataResult.length === 0 ? 'processing' : 'waiting',
                tagNumber: data.tagNumber,
                purchaseDate: data.readedResults.purchaseDate,
                value: data.readedResults.value,
                currencyIsoCode: data.readedResults.currencyIsoCode,
                id: data.id, 
                brand: data.brand
            });
        });
        setTagNumbersAccepted(dataResult);
    }, [tagNumbers, actionSetTags]);

    const actionConfirm = useCallback(() => {
        actionSetTags(tagNumbersAccepted);
    }, [actionSetTags, tagNumbersAccepted]);

    const actionChangeRadio = useCallback((dataTag: TagCheckedT) => {
        setTagNumbersAccepted(prevTags =>
            prevTags.map(tag => ({
                ...tag,
                status: tag.id === dataTag.id ? 'processing' : 'waiting',
            }))
        );
    }, []);

    const title = useMemo(() => () => {
        try{
            return parse(t('createClaim.tagChooseHeader', { tagNumber: `<strong>${t('createClaim.sumTag')}</strong>` }));
        } catch(error){
            return '';
        }
    }, [t]);

    return (
        <CustomModal open={show}>
            <CustomModalHeader onClose={actionClose} title={t('createClaim.tagHeader')}/>
            <div className={style.body}>
                {title()}
                <ul>
                    {tn.map((tn, idx) => (
                        <li key={`tg_${idx}`}>
                            <div className={style.line}>
                                <RadioButton
                                    on={tagNumbersAccepted.some(t => t.status === 'processing' && t.id === tn.id)}
                                    action={() => actionChangeRadio(tn)}
                                />
                                <span onClick={() => actionChangeRadio(tn)}>
                                    {[
                                        tn.tagNumber && <strong key="tagNumber">{tn.tagNumber}</strong>,
                                        tn.brand || '',
                                        tn.color?.split('-')[1]?.trim() || '',
                                    ]
                                        .filter(Boolean)
                                        .map((item, index, array) =>
                                            index < array.length - 1 ? [item, ', '] : item
                                        )}
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className={style.buttons}>
                    <Button onClick={actionClose} color={'secondary'}>{t('common.cancel')}</Button>
                    <Button disabled={tn.filter(t => t.checked).length <1} onClick={actionConfirm}>{t('common.confirm')}</Button>
                </div>
            </div>
        </CustomModal>
    );
}
export default ModalTagNumbers;