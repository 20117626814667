import { Button} from 'Components';
import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import useTranslate from 'Hooks/useTranslate';

import { RessultAddressByLocT } from 'Helpers/googleApi';
import style from './ModalConfirmAddress.module.scss';
import CustomModalHeader from 'Components/CustomModalHeader/CustomModalHeader';
import CustomModal from 'Components/CustomModal/CustomModal';

type ContactDataT = {
    country: {
        code: string,
        name: string
    },
    city: string,
    address: string,
    zipCode: string,
    phone: string,
    travelling14days: boolean|null,
    coords: { lat: number, lon: number }
}

type MainPropsT = {
    actionClose: () => void,
    actionConfirm: () => void,
    updateClaim: (dataType: string, data: any) => void,
    possibleAddresses: RessultAddressByLocT[],
    show: boolean,
    contactData: ContactDataT
}

const ModalConfirmAddress: React.FC<MainPropsT> = ({ actionClose, show, actionConfirm, possibleAddresses, updateClaim, contactData }) => {
    const t = useTranslate();
    const [selectedAddress, setSelectedAddress] = useState<RessultAddressByLocT | null>(null);

    const actionChangeAddressData = useCallback((address: RessultAddressByLocT) => {
        updateClaim('contact', {
            ...contactData,
            country: {
                name: address.countryName,
                code: address.countryCode
            },
            city: address.city ? address.city : address.province || address.subCity,
            zipCode: address.postalCode,
            address: `${address.streetName || address.city} ${address.streetNumber}`
        });
    }, [updateClaim, contactData]);

    const handleAddressClick = useCallback((address: RessultAddressByLocT, event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setSelectedAddress(address);
    }, []);

    const handleConfirm = useCallback(() => {
        if (selectedAddress) {
            actionChangeAddressData(selectedAddress)
        }
        actionConfirm();
    }, [selectedAddress, actionConfirm, actionChangeAddressData]);

    const handleModalClick = useCallback(() => {
        setSelectedAddress(null);
    }, []);
    

    return (
        <CustomModal open={show}>
            <CustomModalHeader title={t('createClaim.contactIncorrect')} onClose={actionClose} />
            <div className={style.root} onClick={handleModalClick}>
                <div className={style.body}>
                    {t('createClaim.contactIncorrectContent')}
                    <div>
                        <p className={style.addressDesc}>{t('createClaim.yourCurrentAddress')}</p>
                        <div className={style.addressList}>
                            <p className={cx(style.address, !selectedAddress ? style.selected : '')}>{`${contactData.address}, ${contactData.zipCode} ${contactData.city}, ${contactData.country.name}`}</p>
                        </div>
                    </div>
                    {possibleAddresses.length > 0 && (
                        <div>
                            <p className={style.addressDesc}>{possibleAddresses.length > 1 ? t('createClaim.possibleValidAddresses') : t('createClaim.possibleValidAddressesOne')}</p>
                            <ul className={style.addressList}>
                                {possibleAddresses.map((address, index) => (
                                    <li
                                        key={index}
                                        onClick={(event) => handleAddressClick(address, event)}
                                        className={cx(style.address, selectedAddress === address ? style.selected : '')}
                                    >
                                        {address.fullAdress}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className={style.buttons}>
                    <Button onClick={actionClose} color={'secondary'}>{t('createClaim.contactIncorrectCorrect')}</Button>
                    <Button onClick={handleConfirm}>{t('common.continue')}</Button>
                </div>
            </div>
        </CustomModal>
    );
}
export default ModalConfirmAddress;