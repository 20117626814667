import React from 'react';
import cx from 'classnames';
// import { MessageModal } from 'Components';
import styles from './ActionModal.module.scss';
import { Button } from 'Components';

interface IActionModalItem {
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactElement;
  text: string;
  description?: string;
  className?: string;
  color?: "primary" | "secondary";
}

// interface IActionModal {
//   open: boolean;
//   children: React.ReactNode | string;
//   onClose: (evt: React.MouseEvent<HTMLElement>) => void;
//   closeOnClickOutside?: boolean;
//   className?: string;
// }

export default function ActionModalItem(props: IActionModalItem) {
  return (
<li className={cx(styles.listItem, props.className)}>
  <Button onClick={props.onClick} color={props?.color}>
    <span
      className={cx(styles.main, {
        [styles.whiteText]: props.color === 'secondary',
        [styles.blackText]: props.color === 'primary',
      })}
    >
      {props.text}
    </span>
  </Button>
</li>
  );
}

// export default function ActionModal(props: IActionModal) {
//   const { open, closeOnClickOutside, className, onClose } = props;

//   return (
//     <MessageModal open={open} onClose={onClose} closeOnClickOutside={closeOnClickOutside}>
//       <ul className={cx(styles.list, className)}>{props.children}</ul>
//     </MessageModal>
//   );
// }
