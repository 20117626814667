import { useTranslation } from 'react-i18next';
import { Button } from 'Components';
import { ReactComponent as EditIcon } from 'Assets/editIcon.svg';
import styles from './ReferenceNumberConfirmationModal.module.scss';
import CustomModal from 'Components/CustomModal/CustomModal';

enum POSITION {
  CENTER = 'center',
  BOTTOM = 'bottom',
}

interface IReferenceNumberConfirmationModal {
  open: boolean;
  close: () => void;
  confirm: () => void;
  edit: () => void;
  referenceNumber: string;
  position: POSITION;
}

export default function ReferenceNumberConfirmationModal(props: IReferenceNumberConfirmationModal) {
  const { t } = useTranslation();
  return (
    <CustomModal
      open={props.open}
      onClose={props.close}
    >
      <div className={styles.root}>
        <h2 className={styles.title}>{t('referenceNumberConfirmationModal.title')}</h2>

        <p className={styles.description}>{t('referenceNumberConfirmationModal.subtitle')}</p>

        <div className={styles.editRow}>
          <span>{props.referenceNumber}</span>
          <EditIcon onClick={props.edit} />
        </div>

        <Button onClick={props.confirm} className={styles.confirm} color="secondary">
          {t('referenceNumberConfirmationModal.confirm')}
        </Button>
      </div>
    </CustomModal>
  );
}

ReferenceNumberConfirmationModal.POSITION = POSITION;
