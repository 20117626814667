
import { Button } from "Components";
import React from 'react';

import useTranslate from "Hooks/useTranslate";
import style from './ModalNotSupportedAirlinesAndContacts.module.scss';
import CustomModalHeader from "Components/CustomModalHeader/CustomModalHeader";
import CustomModal from "Components/CustomModal/CustomModal";


type MainPropsT = {
    actionClose: () => void,
    actionCloseModalMain: () => void,
    url: string,
    show?: boolean,
}

const ModalNotSupportedAirlinesAndContacts: React.FC<MainPropsT> = ({ actionClose, actionCloseModalMain, url, show = false }) => {
    const t = useTranslate();

    return (
        <CustomModal open={show} >
            <CustomModalHeader 
                title={t('createClaim.announcement')}
                onClose={actionClose}
            />      
            <div className={style.root}>
                {t('createClaim.noSupportAirlineAndContact')}
            </div>
            <div className={style.link}>
                <a href={url} target="_blank" rel="noreferrer" >{t('createClaim.contactAirline')}</a>
            </div>
            <div className={style.buttons}>
                <Button onClick={actionCloseModalMain} type={'button'} color={'primary'} block>{t('createClaim.duplicateModalCloseButton')}</Button>
            </div>
        </CustomModal>
    );
}

export default ModalNotSupportedAirlinesAndContacts;
